import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
import ChecklistResourcesSection from "../../../components/ChecklistResourcesSection/ChecklistResourcesSection.jsx";
import { checklists } from "../../../layout/Constant.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <ChecklistResourcesSection figmaUrl={checklists.componentImage.figmaUrl.android} codeUrl={checklists.componentImage.codeUrl.android} checklists={checklists.componentImage.checklists} mdxType="ChecklistResourcesSection" />
    <div className="divi" />
    <p>{`The Image component is used to display images. with capability can load from anything, like url, colors, bitmap, drawable or etc.`}</p>
    <div className="divi" />
    <h2>{`Usage`}</h2>
    <div {...{
      "className": "line",
      "style": {
        "backgroundColor": "white"
      }
    }}>{`
  `}<div parentName="div" {...{
        "className": "item-one",
        "align": "center"
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "7px 0"
          }
        }}>{`
      `}<img parentName="div" {...{
            "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/images/image_legion.gif",
            "align": "center",
            "width": 250,
            "height": 500,
            "alt": "image - android",
            "resize-mode": "contain"
          }}></img>{`
    `}</div>{`
  `}</div>
    </div>
    <h3><strong parentName="h3">{`Basic`}</strong></h3>
    <h4>{`Image using Drawable`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-xml"
      }}>{`<com.telkom.legion.component.image.LgnImageView
  android:layout_width="@dimen/dimen_64dp"
  android:layout_height="@dimen/dimen_64dp"
  android:layout_marginTop="@dimen/dimen_16dp"
  android:layout_marginStart="@dimen/dimen_16dp"
  android:src="@drawable/img_ktp"/>

`}</code></pre>
    <h4>{`Image using Color`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-xml"
      }}>{`<com.telkom.legion.component.image.LgnImageView
  android:layout_width="@dimen/dimen_64dp"
  android:layout_height="@dimen/dimen_64dp"
  android:src="?attr/colorPrimary"
  android:layout_marginTop="@dimen/dimen_16dp"
  android:layout_marginStart="@dimen/dimen_16dp"/>

`}</code></pre>
    <h4>{`Image using Url`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-xml"
      }}>{`<com.telkom.legion.component.image.LgnImageView
  android:layout_width="@dimen/dimen_64dp"
  android:layout_height="@dimen/dimen_64dp"
  android:layout_marginTop="@dimen/dimen_16dp"
  android:layout_marginStart="@dimen/dimen_16dp"
  app:src="https://oratakashi.com/images/weeding/female.jpg" />

`}</code></pre>
    <h3><strong parentName="h3">{`Radius`}</strong></h3>
    <h4>{`Image radius 0dp`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-xml"
      }}>{`<com.telkom.legion.component.image.LgnImageView
  android:layout_width="@dimen/dimen_64dp"
  android:layout_height="@dimen/dimen_64dp"
  android:layout_marginStart="@dimen/dimen_16dp"
  android:layout_marginTop="@dimen/dimen_16dp"
  app:src="https://oratakashi.com/images/weeding/female.jpg" />


`}</code></pre>
    <h4>{`Image radius 8dp`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-xml"
      }}>{`<com.telkom.legion.component.image.LgnImageView
  android:layout_width="@dimen/dimen_64dp"
  android:layout_height="@dimen/dimen_64dp"
  android:layout_marginStart="@dimen/dimen_16dp"
  android:layout_marginTop="@dimen/dimen_16dp"
  android:radius="8dp"
  app:src="https://oratakashi.com/images/weeding/female.jpg" />

`}</code></pre>
    <h4>{`Image radius 360dp`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-xml"
      }}>{`<com.telkom.legion.component.image.LgnImageView
  android:layout_width="@dimen/dimen_64dp"
  android:layout_height="@dimen/dimen_64dp"
  android:layout_marginStart="@dimen/dimen_16dp"
  android:layout_marginTop="@dimen/dimen_16dp"
  android:radius="360dp"
  app:src="https://oratakashi.com/images/weeding/female.jpg" />

`}</code></pre>
    <div className="divi" />
    <h2>{`Attributes`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Xml Attrs`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Related method(s)`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Load Drawable Image`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`app:src`}</inlineCode>{` or `}<inlineCode parentName="td">{`android:src`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`resDrawable`}</inlineCode>{` or `}<inlineCode parentName="td">{`resId`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To load drawable image from xml`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Load Colors`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`app:src`}</inlineCode>{` or `}<inlineCode parentName="td">{`android:src`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`colors`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To load colors in image from xml`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Load Image Url`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`app:src`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`url`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To load Url in image from xml`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Set Corner Radius`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`android:radius`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`radius`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To set image radius in image from xml`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Set Cache`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`app:cache`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`cache`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To set cache in image from xml`}</td>
        </tr>
      </tbody>
    </table>
    <div className="divi" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      